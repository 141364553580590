import React from 'react';
import './App.css';

function App() {
    return (
        <div className="Aligner">
            <div className="Aligner-item">
                <h1>Coming soon for everyone</h1><br/>
                <a href="https://rokguides.ru">https://rokguides.ru</a>
            </div>
        </div>
    );
}

export default App;
